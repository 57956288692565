
import { css } from "@emotion/react";
import { Link, useLocation } from "react-router-dom";
import React, { useCallback, useState } from "react";
import { BACKGROUND_COLOR, FONT_COLOR } from "../../resources/color";
// import { LeftBalloon } from "../atoms/LeftBalloon";
import { TagList } from "./TagList";
import { UserName } from "./UserName";
import useDeviceType from "../../hooks/useDeviceType";
import { PostModal } from "../organisms/PostModal";

type Props = {
  userId: string,
  id: string,
  title: string,
  imageUrl: string,
  icon?: string,
  name?: string,
  tags?: any[],
  likes?: number,
  onClickDeleteButton: (id: string) => void
}

export const ThumbnailCard: React.FC<Props> = ({userId, id, title, imageUrl, icon = '🐤', name = 'ユーザーはいません', tags, likes = 0, onClickDeleteButton}) => {
  // const [likeCount, setLikeCount] = useState<number>(likes)
  // const user = useRecoilValue(userState)
  const location = useLocation()
  const deviceType = useDeviceType()
  const [showPostModal, setShowPostModal] = useState<boolean>(false)

  // const onClickLike = useCallback(() => {
  //   setLikeCount((prev) => prev + 1)
  // }, [])

  const upperStyle = css({
    display: "flex",
    flexDirection: 'column',
  })

  const onClickEditButton = useCallback((id: string) => {
    setShowPostModal(true)
  }, [])

  const renderButton = useCallback((type: 'edit' | 'delete') => {
    if (location.pathname !== '/myposts') return
    const label = type === 'edit' ? '編集する' : '削除する'
    return (
      <button 
        css={buttonStyle(type)} 
        onClick={() => type === 'edit' ? onClickEditButton(id) : onClickDeleteButton(id)}
      >
        {label}
      </button>
    )
  }, [id, location.pathname, onClickDeleteButton, onClickEditButton])
  const onClosePostModal = useCallback(() => {
    setShowPostModal(false)
  }, [])

  const cardWidth = deviceType === 'mobile' ? 'calc(100vw - 20px)' : 'calc((100vw - 100px) / 3 - 2px)'
  const cardItemStyle = css({
    width: cardWidth,
    overflow: "hidden",
    borderRadius: 10,
    display: "flex",
    flexDirection: 'column',
    border: '1px solid black',
    justifyContent: 'space-between',
    gap: 15
  })
  const cardImageStyle = css({
    width: '100%',
    height: `calc(${cardWidth} * 2 / 3)`,
    objectFit: "cover",
  })

  return (
    <div css={cardItemStyle}>
      <div css={upperStyle}>
        <Link to={`/post/${id}`} css={cardTitleStyle}>{title}</Link>
        <Link to={`/post/${id}`}><img css={cardImageStyle} src={imageUrl} alt={title} /></Link>
        <div css={cardInfoStyle}>
          <div css={infoContainerStyle}>
            <div css={postUserStyle}>
              <UserName icon={icon} name={name} />
            </div>
            <div css={tagContainerStyle}>
              <TagList items={tags} fontSize={12} />
            </div>
          </div>
          {/* <span css={likeContainerStyle}>
            <span css={likeTextStyle} onClick={onClickLike}>Nice</span>
            <LeftBalloon text={`${likeCount}`} />
          </span> */}
        </div>
      </div>
      <div css={buttonsStyle}>
        {renderButton('edit')}
        {renderButton('delete')}
      </div>
      {showPostModal && <PostModal onClose={onClosePostModal} id={id} />}
    </div>
  )
}
const postUserStyle = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 5,
})
const cardInfoStyle = css({
  display: "flex",
  flexDirection: 'column',
  gap: 10,
})
const tagContainerStyle = css({
  height: 'auto',
  display: "flex",
  flexDirection: "row",
  gap: 10,
})
const infoContainerStyle = css({
  display: "flex",
  flexDirection: "column",
  gap: 12,
  padding: '10px 20px'
})
const cardTitleStyle = css({
  fontSize: 18,
  fontWeight: 800,
  color: FONT_COLOR.PRIMARY,
  padding: '14px 20px',
  textDecoration: 'none'
})
// const likeContainerStyle = css({
//   width: '100%',
//   borderTop: `1px solid ${BORDER_COLOR.PRIMARY}`,
//   padding: '15px 20px',
//   display: 'flex',
//   flexDirection: 'row',
//   gap: 10,
//   alignItems: 'center',
// })
// const likeTextStyle = css({
//   color: FONT_COLOR.PRIMARY,
//   fontSize: 14,
//   cursor: 'pointer',
//   userSelect: 'none'
// })
const buttonsStyle = css({
  display: 'flex',
  flexDirection: 'column',
})
const buttonStyle = (type: 'edit' | 'delete') => css({
  margin: '0 10px 10px',
  background: type === 'edit' ? BACKGROUND_COLOR.ACCENT : BACKGROUND_COLOR.ATTENTION,
  color: FONT_COLOR.REVERSED,
  fontWeight: 'bold',
  border: 'none',
  borderRadius: 5,
  height: 40,
  cursor: 'pointer'
})