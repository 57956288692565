import React, { useCallback, useState } from "react";
import firebase from 'firebase/compat/app';
import { css } from "@emotion/react"
import { Link, useNavigate } from "react-router-dom";
// import ToriLogo from '../../assets/tori_logo.png'
import { useRecoilState } from "recoil";
import { userState } from "../../store/userState";
import { BACKGROUND_COLOR, FONT_COLOR } from "../../resources/color";
import { HeaderPortal } from "../portal/HeaderPortal";
import { UserIcon } from "../atoms/UserIcon";
import { PostModal } from "./PostModal";
import useDeviceType from "../../hooks/useDeviceType";

export const Header: React.FC = () => {
  const [showMyMenu, setShowMyMenu] = useState<boolean>(false)
  const [showPostModal, setShowPostModal] = useState<boolean>(false) 
  const [user, setUser] = useRecoilState(userState);
  const navigate = useNavigate()
  const deviceType = useDeviceType()

  const myMenuContainerStyle = css({
    width: 240,
    height: 'auto',
    position: "absolute",
    top: 90,
    right: 30,
    borderRadius: 20,
    display: showMyMenu ? 'flex' : 'none',
    flexDirection: 'column',
    background: BACKGROUND_COLOR.PRIMARY,
    border: '1px solid #000000'
  })

  const onClickNew = useCallback(() => {
    setShowPostModal(true)
  }, [])
  const toggleAvatar = useCallback((show: boolean) => {
    setShowMyMenu(show)
  }, [])
  const onClickMyPosts = useCallback(() => {
    toggleAvatar(false)
    navigate('/myposts')
  }, [navigate, toggleAvatar])
  const onClickSettings = useCallback(() => {
    toggleAvatar(false)
    navigate('/settings')
  }, [navigate, toggleAvatar])
  const onClickLogout = useCallback(() => {
    toggleAvatar(false)
    firebase.auth().signOut().then(() => {
      navigate('/')
      setUser(null)
    }).catch((e) => {
      console.log('error: ', e)
    })
  }, [setUser, navigate, toggleAvatar])
  const onClickMenuButton = useCallback(() => {
    navigate('/login')
    toggleAvatar(false)
  }, [navigate, toggleAvatar])
  const onClosePostModal = useCallback(() => {
    setShowPostModal(false)
  }, [])
  const renderMenu = useCallback(() => {
    if (user) return (
      <>
        <span css={menuButtonStyle} onClick={onClickNew}>投稿</span>
        <div css={avatarContainerStyle} onMouseOver={() => toggleAvatar(true)} onMouseLeave={() => toggleAvatar(false)}>
          <UserIcon icon="🐤" size={38} />
          {/* <img css={avatarStyle} src={UserIcon} alt="user-icon" /> */}
        </div>
        <div css={myMenuContainerStyle} onMouseOver={() => toggleAvatar(true)} onMouseLeave={() => toggleAvatar(false)}>
          <div css={nameContainerStyle}>
            <span css={menuNameStyle}>{user.userName}さん</span>
            <span css={menuUserIdStyle}>{user.userId}</span>
          </div>
          <div css={myMenuButtonsStyle}>
            <span css={myMenuButtonStyle} onClick={onClickMyPosts}>じぶんの投稿</span> 
            <span css={myMenuButtonStyle} onClick={onClickSettings}>設定</span> 
            <span css={myMenuButtonStyle} onClick={onClickLogout}>ログアウト</span>
          </div>
        </div>
        {showPostModal && <PostModal onClose={onClosePostModal} />}
      </>
    )
    return (<span css={menuButtonStyle} onClick={onClickMenuButton}>ログイン</span>)
  }, [myMenuContainerStyle, onClickLogout, onClickMyPosts, onClickNew, toggleAvatar, user, onClickSettings, onClickMenuButton, onClosePostModal, showPostModal])

  const headerContainerStyle = css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: "space-between",
    position: "fixed",
    backdropFilter: 'blur(5px)',
    top: 0,
    left: 0,
    right: 0,
    padding: deviceType === 'mobile' ? '10px 20px' : '20px 30px',
  })
  const titleStyle = css({
    fontWeight: 800,
    fontSize: deviceType === 'mobile' ? 18 : 22,
    lineHeight: 1,
    color: FONT_COLOR.PRIMARY
  })

  return (
    <HeaderPortal>
      <div css={headerContainerStyle}>
        <Link to='/' css={logoStyle}>
          {/* <img css={imageStyle} src={ToriLogo} alt="logo" /> */}
          <span css={titleStyle}>うちのとりまわりじまん</span>
        </Link>
        <div css={menuContainerStyle}>
          {renderMenu()}
        </div>
      </div>
    </HeaderPortal>
  )
}
const logoStyle = css({
  display: "flex",
  flexDirection: "row",
  justifyContent: 'center',
  alignItems: 'center',
  textDecoration: 'none',
  gap: 10
})
// const imageStyle = css({
//   width: 50,
// })
const menuContainerStyle = css({
  display: "flex",
  flexDirection: "row",
  gap: 20,
  alignItems: "center",
  height: 70,
})
const menuButtonStyle = css({
  fontWeight: 800,
  fontSize: 18,
  cursor: 'pointer',
  textDecoration: 'none',
  color: FONT_COLOR.PRIMARY
})
const nameContainerStyle = css({
  borderBottom: '1px solid #000000',
  display: 'flex',
  flexDirection: 'column',
  padding: '18px 28px',
})
const menuNameStyle = css({
  fontSize: 16,
  fontWeight: 800,
})
const menuUserIdStyle = css({
  fontSize: 10,
  color: FONT_COLOR.SECONDARY
})
const myMenuButtonsStyle = css({
  padding: '18px 28px',
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  fontSize: 16,
})
const myMenuButtonStyle = css({
  cursor: 'pointer',
  lineHeight: 1
})
const avatarContainerStyle = css({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer'
})
// const avatarStyle = css({
//   width: 38,
//   height: 38,
//   borderRadius: '50%',
//   cursor: 'pointer'
// })