import React, { useEffect } from "react";
import { RouterProvider, createBrowserRouter, ScrollRestoration, Outlet } from "react-router-dom";
import { Post } from "./components/pages/Post";
import { Login } from "./components/pages/Login";
import { Top } from "./components/pages/Top";
import firebase from 'firebase/compat/app';
import { MyPosts } from "./components/pages/MyPosts";
import { Footer } from "./components/atoms/Footer";
import { RecoilRoot } from "recoil";
import { NotFound } from "./components/pages/NotFound";
import { Header } from "./components/organisms/Header";
import ReactGA from "react-ga4";
import { Settings } from "./components/pages/Settings";
import { css } from "@emotion/react";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}
firebase.initializeApp(firebaseConfig);

const Layout = () => {
  useEffect(() => {
    ReactGA.send("pageview");
  }, [])

  const bodyStyle = css({
    display: 'flex',
    flexDirection: 'column',
  })

  return (
    <>
      <Header />
      <div css={bodyStyle}>
        <Outlet />
        <Footer />
      </div>
      <ScrollRestoration />
    </>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      { index: true, element: <Top />, errorElement: <NotFound /> },
      { path: '/login', element: <Login />, errorElement: <NotFound /> },
      { path: '/post/:id', element: <Post />, errorElement: <NotFound /> },
      { path: '/myposts', element: <MyPosts />, errorElement: <NotFound /> },
      { path: '/settings', element: <Settings />, errorElement: <NotFound /> },
      { path: '*', element: <NotFound /> },
      // メンテナンス時は下記2つを有効にして他のページを全てをコメントアウトする。Layoutもコメントアウトする。
      // { index: true, element: <Maintenance /> },
      // { path: '*', element: <Maintenance /> },
    ],
  },

]);

export const Router: React.FC = () => {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID as string);
  }, [])

  // usePageTracking()
  return (
    <RecoilRoot>
      <RouterProvider router={router} />
    </RecoilRoot>
  );
}