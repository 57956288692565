
import { css } from "@emotion/react";
import React from "react";
import { TagItem } from "../atoms/TagItem";

type Props = {
  items?: any[],
  color?: string,
  fontSize?: number,
  onChangeTags?: (item: any) => void
}

export const TagList: React.FC<Props> = ({ items = [], color, fontSize, onChangeTags }) => {
  const tagContainerStyle = css({
    height: 'auto',
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 12,
    alignItems: "center",
  })

  return (
    <div css={tagContainerStyle}>
      {items && items.map((t, i) => 
        <TagItem item={t} key={`tag-item-${i}`} defaultColor={color} fontSize={fontSize} onChangeSelected={onChangeTags} />
      )}
    </div>
  )
}